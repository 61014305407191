import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/source-sans-pro";

let theme = createTheme({
  components: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "white"
        },
        "&$focused $notchedOutline": {
          borderColor: "white"
        },
        "&:hover $notchedOutline": {
          borderColor: "grey"
        },
        "&$focused $notchedOutline $legendLabelled": {
          color: "white"
        },
        color: "white"
      },
      notchedOutline: {}
    },
    MuiButton: {
      outlined: {
        "&:hover": {
          background: "rgba(255,255,255,0.5)",
          borderColor: "rgba(255,255,255,1)",
        },
      },
      outlinedPrimary: {
        "&:hover": {
          background: "rgba(255,255,255,0.5)",
          borderColor: "rgba(255,255,255,0.5)",
        },
      },
    },
    MuiButtonGroup: {
      groupedOutlinedPrimary:{
        borderColor: "rgba(0,0,1,0.2)",
        "&:hover": {
          borderColor: "rgba(0,0,1,0.2)",
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: "white",
        "&$focused":{
          color: "#fff",
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#005085',
      main: '#1004b4',
      dark: '#000540',
      darkness: '#000220',
      contrastText: '#f1f7f7',
      mainGradient: 'linear-gradient(45deg, #000562 1%, #1004b4 100%)',
    },
    secondary: {
      light: '#f086a4',
      main: '#f01a56',
      dark: '#a2123a',
      contrastText: '#fff',
      mainGradient: 'linear-gradient(45deg, #5e4dff 10%, #f01a56 100%)'
    },
    error: {
      light: '#f98470',
      main: '#f92500',
      dark: '#881400'
    },
    warning: {
      light: '#ffdc73',
      main: '#ffbf00',
      dark: '#b18500',
    },
    success: {
      light: '#75baa1',
      main: '#1ea876',
      dark: '#105a40',
    },
    info: {
      light: '#c6c0ff',
      main: '#5e4dff',
      dark: '#3e33a7',
    },
    background: {
      default: '#fafafa',
      paper:  'rgba(255,255,255,0.4)',
    },
    action: {
      disabledBackground: 'rgba(255,255,255,0.1)',
      disabled: '#708d9b',
    },
    text: {
      primary: '#000B5F',
      secondary: '#ffffff',
      disable: '#f1f7f7',
      hint: '#c6d2d7',
    },
  },
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 500,
    fontSize: 18,
    fontFamily: [
      'Roboto Condensed',
      'Source Sans Pro',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: 70,
      fontFamily: 'Roboto Condensed',
      lineHeight: 1.15,
    },
    h2: {
      fontWeight: 700,
      fontSize: 60,
      fontFamily: 'Roboto Condensed',
      lineHeight: 1.12,
    },
    h3: {
      fontWeight: 400,
      fontSize: 50,
      fontFamily: 'Roboto Condensed',
      lineHeight: 1,
    },
    h4: {
      fontWeight: 700,
      fontFamily: 'Roboto Condensed',
      lineHeight: 1.1,
      fontSize: 40,
    },
    h5: {
      fontWeight: 700,
      fontFamily: 'Roboto Condensed',
      lineHeight: 1.1,
      fontSize: 30,
    },
    h6: {
      fontWeight: 700,
      fontFamily: 'Roboto Condensed',
      lineHeight: 1,
      fontSize: 20,
    },
    subtitle1: {
      fontSize: 20,
      fontFamily: 'Source Sans Pro',
      lineHeight: 1.33,
    },
    subtitle2: {
      fontSize: 16,
      fontFamily: 'Source Sans Pro'
    },
    subtitle3: {
      fontSize: 12,
      fontFamily: 'Roboto Condensed',
    },
    body1: {
      fontWeight: 300,
      fontFamily: 'Source Sans Pro',
      lineHeight: 1.33,
      fontSize: 18,
    },
    body2: {
      fontSize: 18,
      fontWeight: 400,
      fontFamily: 'Roboto Condensed',
      lineHeight: 1.33,
    },
    body3: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Roboto Condensed',
      lineHeight: 1.2,
    },
    button:{
      fontSize: 16,
      lineHeight: 1.6,
    },
    caption:{
    },
    overline:{
      fontFamily: 'Roboto Condensed',
      letterSpacing: .38
    },
  },
});
theme = responsiveFontSizes(theme);

export {theme};
