import React from "react"

import Layout from "../components/common/Layout"
import Seo from "../components/seo"
import ContactForm  from "../components/ContactForm"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/source-sans-pro";

const Contact = () => (
  <Layout>
    <Seo title="Contacto" />
    <ContactForm />
 </Layout>
)

export default Contact
